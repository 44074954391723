@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/images' as img;

.main-section {
    margin: fn.spacing(4) 0;
}

.title {
    margin-bottom: fn.spacing(2);
}

.term a {
    color: var.$color-content-primary;
}

.term a:hover {
    color: var.$color-content-accent;
}

.letter {
    margin-top: fn.spacing(3);
}

.letter-section {
    scroll-margin-top: fn.spacing(12);
}

.vertical-menu {
    padding: 0;
    position: sticky;
    top: fn.spacing(12);
    margin-bottom: fn.spacing(4);

    .item {
        padding: 0;
        white-space: normal;
        min-height: auto;
        background-color: var.$color-neutral-0;

        a {
            border-left: 4px solid var.$color-border-opaque;
            color: var.$color-neutral-300;
            text-decoration: none;
            width: 100%;
            padding: fn.spacing(1);
            font-weight: 700;

            span {
                padding:  fn.spacing(0.5) fn.spacing(1);
                margin-left: fn.spacing(0.5);
                border-radius: fn.spacing(0.5);
            }
        }
    }

    .item a[data-active='true'] {
        border-color: var.$color-content-accent;
        background-color: var.$color-neutral-0;
        z-index: 1;
        color: var.$color-content-accent;

        span {
            background: var.$color-blue-100;
        }

    }


    .item[data-disabled='true'] a {
        color: var.$color-neutral-150;
    }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {

    .item[data-disabled='false']:hover a,
    .item[data-disabled='false']:active a,
    .item[data-disabled='false']:focus-within a,
    .item[data-disabled='false']:focus a {
        border-color: var.$color-content-accent;
        background-color: var.$color-neutral-0;
        z-index: 1;
        color: var.$color-content-accent;
    }

    .vertical-menu {
        padding-right: fn.spacing(2);
    }
}