@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.achieve-skeleton[data-variant='transparent'] > :first-child > :first-child {
  background-color: rgba(29 37 47 / 11%);

  --custom-highlight-background: linear-gradient(
    90deg,
    rgba(29 37 47 / 11%) 40%,
    rgba(29 37 47 / 11%) 50%,
    rgba(29 37 47 / 11%) 60%
  );
}
